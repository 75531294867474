import dayjs from "dayjs";
import { ICountry } from "app/shared/model/country.model";

export interface IState {
  id?: number;
  stateName?: string | null;
  stateCode?: string | null;
  mapPointX?: number | null;
  mapPointY?: number | null;
  isactive?: number | null;
  created?: dayjs.Dayjs | null;
  modified?: dayjs.Dayjs | null;
  country?: ICountry | null;
}

export const defaultValue: Readonly<IState> = {};
