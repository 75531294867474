import dayjs from "dayjs";
import { ISuUser } from "app/shared/model/su-user.model";
import { ICountry } from "app/shared/model/country.model";
import { IState } from "app/shared/model/state.model";

export interface IAccounts {
  id?: number;
  clientName?: string | null;
  email?: string | null;
  phoneNo?: string | null;
  poc?: string | null;
  address?: string | null;
  fax?: string | null;
  isActive?: number | null;
  created?: dayjs.Dayjs | null;
  modified?: dayjs.Dayjs | null;
  createdBy?: ISuUser | null;
  modifiedBy?: ISuUser | null;
  country_id?: ICountry | null;
  state_id?: IState | null;
}

export const defaultValue: Readonly<IAccounts> = {};
