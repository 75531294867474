import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import {
  Translate,
  ValidatedField,
  ValidatedForm,
  translate,
  isNumber,
} from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  convertDateTimeFromServer,
  convertDateTimeToServer,
  displayDefaultDateTime,
} from "app/shared/util/date-utils";
import { useAppDispatch, useAppSelector } from "app/config/store";

import { getEntities as getOrganisations } from "app/entities/organisation/organisation.reducer";
import { getEntities as getSuUsers } from "app/entities/su-user/su-user.reducer";
import {
  createEntity,
  getEntity,
  reset,
  updateEntity,
} from "./division.reducer";

export const DivisionUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<"id">();
  const isNew = id === undefined;

  const organisations = useAppSelector((state) => state.organisation.entities);
  const suUsers = useAppSelector((state) => state.suUser.entities);
  const divisionEntity = useAppSelector((state) => state.division.entity);
  const loading = useAppSelector((state) => state.division.loading);
  const updating = useAppSelector((state) => state.division.updating);
  const updateSuccess = useAppSelector((state) => state.division.updateSuccess);

  const handleClose = () => {
    navigate("/division");
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getOrganisations({}));
    dispatch(getSuUsers({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = (values) => {
    if (values.id !== undefined && typeof values.id !== "number") {
      values.id = Number(values.id);
    }
    if (
      values.is_active !== undefined &&
      typeof values.is_active !== "number"
    ) {
      values.is_active = Number(values.is_active);
    }
    values.created_date = convertDateTimeToServer(values.created_date);
    values.modified_date = convertDateTimeToServer(values.modified_date);

    const entity = {
      ...divisionEntity,
      ...values,
      organisation: organisations.find(
        (it) => it.id.toString() === values.organisation?.toString(),
      ),
      modifiedby: suUsers.find(
        (it) => it.id.toString() === values.modifiedby?.toString(),
      ),
      createdby: suUsers.find(
        (it) => it.id.toString() === values.createdby?.toString(),
      ),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          created_date: displayDefaultDateTime(),
          modified_date: displayDefaultDateTime(),
          modified_by: divisionEntity?.created_by?.id,

        }
      : {
          ...divisionEntity,
          created_date: convertDateTimeFromServer(divisionEntity.created_date),
          modified_date: displayDefaultDateTime(
            //divisionEntity.modified_date,
          ),
          organisation: divisionEntity?.organisation?.id,
         // modifiedby: divisionEntity?.modifiedby?.id,
          createdby: divisionEntity?.createdby?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2
            id="CorpvineApp.division.home.createOrEditLabel"
            data-cy="DivisionCreateUpdateHeading"
          >
            <Translate contentKey="CorpvineApp.division.home.createOrEditLabel">
              Create or edit a Division
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm
              defaultValues={defaultValues()}
              onSubmit={saveEntity}
            >
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="division-id"
                  label={translate("global.field.id")}
                  validate={{ required: true }}
                />
              ) : null}
            <ValidatedField
                          id="division-organisation"
                          name="organisation"
                          data-cy="organisation"
                          label={translate("CorpvineApp.division.organisation")}
                          type="select"
                          required
                        >
                          <option value="" key="0" />
                          {organisations
                            ? organisations.map((otherEntity) => (
                                <option value={otherEntity.id} key={otherEntity.id}>
                                  {otherEntity.org_code}
                                </option>
                              ))
                            : null}
                        </ValidatedField>
              <ValidatedField
                label={translate("CorpvineApp.division.division_code")}
                id="division-division_code"
                name="division_code"
                data-cy="division_code"
                type="text"
                required
              />
              <ValidatedField
                label={translate("CorpvineApp.division.division_name")}
                id="division-division_name"
                name="division_name"
                data-cy="division_name"
                type="text"
                required
              />
              <ValidatedField
                label={translate("CorpvineApp.division.division_description")}
                id="division-division_description"
                name="division_description"
                data-cy="division_description"
                type="text"
              />
              <ValidatedField
                label={translate("CorpvineApp.division.is_active")}
                id="division-is_active"
                name="is_active"
                data-cy="is_active"
                placeholder="1-Active or 0-Inactive"
                type="text"
                validate={{
                                     required: {
                                         value: true,
                                         message: translate("entity.validation.required"),
                                    },
                                                  min: {
                                                    value: 0,
                                                    message: translate("entity.validation.min", { min: 0 }),
                                                  },
                                                  max: {
                                                    value: 1,
                                                    message: translate("entity.validation.max", { max: 1 }),
                                                  },
                                                  validate: (v) =>
                                                    isNumber(v) || translate("entity.validation.number"),
                                                }}
              />
            {/*  <ValidatedField
                label={translate("CorpvineApp.division.created_date")}
                id="division-created_date"
                name="created_date"
                data-cy="created_date"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate("CorpvineApp.division.modified_date")}
                id="division-modified_date"
                name="modified_date"
                data-cy="modified_date"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />*/}

              {!isNew
                  ?(
              <ValidatedField
                id="division-modifiedby"
                name="modifiedby"
                data-cy="modifiedby"
                label={translate("CorpvineApp.division.modifiedby")}
                type="select"
                required
              >
                <option value="" key="0" />
                {suUsers
                  ? suUsers.map((otherEntity) => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.login}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              ):null
              }
              {isNew
                  ?(

              <ValidatedField
                id="division-createdby"
                name="createdby"
                data-cy="createdby"
                label={translate("CorpvineApp.division.createdby")}
                type="select"
                required
              >
                <option value="" key="0" />
                {suUsers
                  ? suUsers.map((otherEntity) => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.login}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              ):null
          }
              <Button
                tag={Link}
                id="cancel-save"
                data-cy="entityCreateCancelButton"
                to="/division"
                replace
                color="info"
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button
                color="primary"
                id="save-entity"
                data-cy="entityCreateSaveButton"
                type="submit"
                disabled={updating}
              >
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default DivisionUpdate;
