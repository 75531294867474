import React from "react";
import { Translate } from "react-jhipster";

import MenuItem from "app/shared/layout/menus/menu-item";

const EntitiesMenu = () => {
  return (
    <>
      {/* prettier-ignore */}
      <MenuItem icon="asterisk" to="/su-user">
        <Translate contentKey="global.menu.entities.suUser" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/application-domain-config">
        <Translate contentKey="global.menu.entities.applicationDomainConfig" />
      </MenuItem>
      {/*<MenuItem icon="asterisk" to="/su-user">
        <Translate contentKey="global.menu.entities.suUser" />
      </MenuItem> */}
      <MenuItem icon="asterisk" to="/organisation">
        <Translate contentKey="global.menu.entities.organisation" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/division">
        <Translate contentKey="global.menu.entities.division" />
      </MenuItem>
      {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
      <MenuItem icon="asterisk" to="/career-growth-goal">
        <Translate contentKey="global.menu.entities.careerGrowthGoal" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/main-job-titles">
        <Translate contentKey="global.menu.entities.mainJobTitles" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/main-positions">
        <Translate contentKey="global.menu.entities.mainPositions" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/prvn-kpi">
        <Translate contentKey="global.menu.entities.prvnKpi" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/project-member-client-roles">
        <Translate contentKey="global.menu.entities.projectMemberClientRoles" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/project-member-cost-types">
        <Translate contentKey="global.menu.entities.projectMemberCostTypes" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/project-member-statuses">
        <Translate contentKey="global.menu.entities.projectMemberStatuses" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/project-member-sysvine-roles">
        <Translate contentKey="global.menu.entities.projectMemberSysvineRoles" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/location">
        <Translate contentKey="global.menu.entities.location" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/category">
        <Translate contentKey="global.menu.entities.category" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/source">
        <Translate contentKey="global.menu.entities.source" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/review">
        <Translate contentKey="global.menu.entities.review" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/skill">
        <Translate contentKey="global.menu.entities.skill" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/skill-category">
        <Translate contentKey="global.menu.entities.skillCategory" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/skill-category-skill-link">
        <Translate contentKey="global.menu.entities.skillCategorySkillLink" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/title-position-link">
        <Translate contentKey="global.menu.entities.titlePositionLink" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/accounts">
        <Translate contentKey="global.menu.entities.accounts" />
      </MenuItem>
    </>
  );
};

export default EntitiesMenu;
