import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Col, FormText, Row } from "reactstrap";
import {
  Translate,
  ValidatedField,
  ValidatedForm,
  translate,
} from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  convertDateTimeFromServer,
  convertDateTimeToServer,
  displayDefaultDateTime,
} from "app/shared/util/date-utils";
import { useAppDispatch, useAppSelector } from "app/config/store";

import { getActiveEntities as getMainJobTitles } from "app/entities/main-job-titles/main-job-titles.reducer";
import { getActiveEntities as getMainPositions } from "app/entities/main-positions/main-positions.reducer";
import {
  createEntity,
  getEntity,
  reset,
  updateEntity,
} from "./title-position-link.reducer";

export const TitlePositionLinkUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<"id">();
  const isNew = id === undefined;

  const mainJobTitles = useAppSelector((state) => state.mainJobTitles.entities);
  const mainPositions = useAppSelector((state) => state.mainPositions.entities);
  const titlePositionLinkEntity = useAppSelector(
    (state) => state.titlePositionLink.entity,
  );
  const loading = useAppSelector((state) => state.titlePositionLink.loading);
  const updating = useAppSelector((state) => state.titlePositionLink.updating);
  const updateSuccess = useAppSelector(
    (state) => state.titlePositionLink.updateSuccess,
  );

  const handleClose = () => {
    navigate(`/title-position-link${location.search}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getMainJobTitles());
    dispatch(getMainPositions());
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = (values) => {
    if (values.id !== undefined && typeof values.id !== "number") {
      values.id = Number(values.id);
    }
    if (values.isactive !== undefined && typeof values.isactive !== "number") {
      values.isactive = Number(values.isactive);
    }
    values.createddate = convertDateTimeToServer(values.createddate);

    const entity = {
      ...titlePositionLinkEntity,
      ...values,
      title: mainJobTitles.find(
        (it) => it.id.toString() === values.title?.toString(),
      ),
      position: mainPositions.find(
        (it) => it.id.toString() === values.position?.toString(),
      ),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createddate: displayDefaultDateTime(),
        }
      : {
          ...titlePositionLinkEntity,
          createddate: convertDateTimeFromServer(
            titlePositionLinkEntity.createddate,
          ),
          title: titlePositionLinkEntity?.title?.id,
          position: titlePositionLinkEntity?.position?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2
            id="CorpvineApp.titlePositionLink.home.createOrEditLabel"
            data-cy="TitlePositionLinkCreateUpdateHeading"
          >
            <Translate contentKey="CorpvineApp.titlePositionLink.home.createOrEditLabel">
              Create or edit a TitlePositionLink
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm
              defaultValues={defaultValues()}
              onSubmit={saveEntity}
            >
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="title-position-link-id"
                  label={translate("global.field.id")}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate("CorpvineApp.titlePositionLink.isactive")}
                id="title-position-link-isactive"
                name="isactive"
                data-cy="isactive"
                type="text"
              />
              <ValidatedField
                label={translate("CorpvineApp.titlePositionLink.createddate")}
                id="title-position-link-createddate"
                name="createddate"
                data-cy="createddate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                id="title-position-link-title"
                name="title"
                data-cy="title"
                label={translate("CorpvineApp.titlePositionLink.title")}
                type="select"
                required
              >
                <option value="" key="0" />
                {mainJobTitles
                  ? mainJobTitles.map((otherEntity) => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.jobtitlename}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              {/*<FormText>
                <Translate contentKey="entity.validation.required">
                  This field is required.
                </Translate>
              </FormText>*/}
              <ValidatedField
                id="title-position-link-position"
                name="position"
                data-cy="position"
                label={translate("CorpvineApp.titlePositionLink.position")}
                type="select"
                required
              >
                <option value="" key="0" />
                {mainPositions
                  ? mainPositions.map((otherEntity) => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.positionname}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              {/*<FormText>
                <Translate contentKey="entity.validation.required">
                  This field is required.
                </Translate>
              </FormText>*/}
              <Button
                tag={Link}
                id="cancel-save"
                data-cy="entityCreateCancelButton"
                to="/title-position-link"
                replace
                color="info"
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button
                color="primary"
                id="save-entity"
                data-cy="entityCreateSaveButton"
                type="submit"
                disabled={updating}
              >
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default TitlePositionLinkUpdate;
