import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { TextFormat, Translate } from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { APP_DATE_FORMAT } from "app/config/constants";
import { useAppDispatch, useAppSelector } from "app/config/store";

import { getEntity } from "./state.reducer";

export const StateDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<"id">();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const stateEntity = useAppSelector((state) => state.state.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="stateDetailsHeading">
          <Translate contentKey="CorpvineApp.state.detail.title">
            State
          </Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{stateEntity.id}</dd>
          <dt>
            <span id="stateName">
              <Translate contentKey="CorpvineApp.state.stateName">
                State Name
              </Translate>
            </span>
          </dt>
          <dd>{stateEntity.stateName}</dd>
          <dt>
            <span id="stateCode">
              <Translate contentKey="CorpvineApp.state.stateCode">
                State Code
              </Translate>
            </span>
          </dt>
          <dd>{stateEntity.stateCode}</dd>
          <dt>
            <span id="mapPointX">
              <Translate contentKey="CorpvineApp.state.mapPointX">
                Map Point X
              </Translate>
            </span>
          </dt>
          <dd>{stateEntity.mapPointX}</dd>
          <dt>
            <span id="mapPointY">
              <Translate contentKey="CorpvineApp.state.mapPointY">
                Map Point Y
              </Translate>
            </span>
          </dt>
          <dd>{stateEntity.mapPointY}</dd>
          <dt>
            <span id="isactive">
              <Translate contentKey="CorpvineApp.state.isactive">
                Isactive
              </Translate>
            </span>
          </dt>
          <dd>{stateEntity.isactive}</dd>
          <dt>
            <span id="created">
              <Translate contentKey="CorpvineApp.state.created">
                Created
              </Translate>
            </span>
          </dt>
          <dd>
            {stateEntity.created ? (
              <TextFormat
                value={stateEntity.created}
                type="date"
                format={APP_DATE_FORMAT}
              />
            ) : null}
          </dd>
          <dt>
            <span id="modified">
              <Translate contentKey="CorpvineApp.state.modified">
                Modified
              </Translate>
            </span>
          </dt>
          <dd>
            {stateEntity.modified ? (
              <TextFormat
                value={stateEntity.modified}
                type="date"
                format={APP_DATE_FORMAT}
              />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="CorpvineApp.state.country">
              Country
            </Translate>
          </dt>
          <dd>{stateEntity.country ? stateEntity.country.country_name : ""}</dd>
        </dl>
        <Button
          tag={Link}
          to="/state"
          replace
          color="info"
          data-cy="entityDetailsBackButton"
        >
          <FontAwesomeIcon icon="arrow-left" />{" "}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button
          tag={Link}
          to={`/state/${stateEntity.id}/edit`}
          replace
          color="primary"
        >
          <FontAwesomeIcon icon="pencil-alt" />{" "}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default StateDetail;
