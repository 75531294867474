import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { TextFormat, Translate } from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { APP_DATE_FORMAT } from "app/config/constants";
import { useAppDispatch, useAppSelector } from "app/config/store";

import { getEntity } from "./su-user.reducer";

export const SuUserDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<"id">();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const suUserEntity = useAppSelector((state) => state.suUser.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="suUserDetailsHeading">
          <Translate contentKey="CorpvineApp.suUser.detail.title">
            SuUser
          </Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{suUserEntity.id}</dd>

          <dt>
              <span id="first_name">
                <Translate contentKey="CorpvineApp.suUser.first_name">
                  First Name
                </Translate>
              </span>
            </dt>
            <dd>{suUserEntity.first_name}</dd>
            <dt>
              <span id="last_name">
                <Translate contentKey="CorpvineApp.suUser.last_name">
                  Last Name
                </Translate>
              </span>
            </dt>
            <dd>{suUserEntity.last_name}</dd>
            <dt>
              <span id="login">
                <Translate contentKey="CorpvineApp.suUser.login">Login</Translate>
              </span>
            </dt>
            <dd>{suUserEntity.login}</dd>
            <dt>
            <span id="email">
              <Translate contentKey="CorpvineApp.suUser.email">Email</Translate>
            </span>
          </dt>
          <dd>{suUserEntity.email}</dd>
          {/*<dt>
            <span id="password_hash">
              <Translate contentKey="CorpvineApp.suUser.password_hash">
                Password Hash
              </Translate>
            </span>
          </dt>
          <dd>{suUserEntity.password_hash}</dd>*/}
           <dt>
           <span id="activated">
             <Translate contentKey="CorpvineApp.suUser.activated">
               Activated
             </Translate>
           </span>
         </dt>
         <dd>{suUserEntity.activated}</dd>
         {/*
         <dt>

           <Translate contentKey="CorpvineApp.suUser.created_by">
             Created By
           </Translate>

       </dt>
       <dd>{suUserEntity.created_by}</dd>
       <dt>
         <span id="created_date">
           <Translate contentKey="CorpvineApp.suUser.created_date">
             Created Date
           </Translate>
         </span>
       </dt>
       <dd>
         {suUserEntity.created_date ? (
           <TextFormat
             value={suUserEntity.created_date}
             type="date"
             format={APP_DATE_FORMAT}
           />
         ) : null}
       </dd>
       <dt>
                   <span id="last_modified_by">
                     <Translate contentKey="CorpvineApp.suUser.last_modified_by">
                       Last Modified By
                     </Translate>
                   </span>
                 </dt>
                 <dd>{suUserEntity.last_modified_by}</dd>
                 <dt>
                   <span id="last_modified_date">
                     <Translate contentKey="CorpvineApp.suUser.last_modified_date">
                       Last Modified Date
                     </Translate>
                   </span>
                 </dt>
                 <dd>
                   {suUserEntity.last_modified_date ? (
                     <TextFormat
                       value={suUserEntity.last_modified_date}
                       type="date"
                       format={APP_DATE_FORMAT}
                     />
                   ) : null}
                 </dd>
            <dt>
            <span id="image_url">
              <Translate contentKey="CorpvineApp.suUser.image_url">
                Image Url
              </Translate>
            </span>
          </dt>
          <dd>{suUserEntity.image_url}</dd>

          <dt>
            <span id="lang_key">
              <Translate contentKey="CorpvineApp.suUser.lang_key">
                Lang Key
              </Translate>
            </span>
          </dt>
          <dd>{suUserEntity.lang_key}</dd>
          <dt>
            <span id="activation_key">
              <Translate contentKey="CorpvineApp.suUser.activation_key">
                Activation Key
              </Translate>
            </span>
          </dt>
          <dd>{suUserEntity.activation_key}</dd>
          <dt>
            <span id="reset_key">
              <Translate contentKey="CorpvineApp.suUser.reset_key">
                Reset Key
              </Translate>
            </span>
          </dt>
          <dd>{suUserEntity.reset_key}</dd>

          <dt>
            <span id="reset_date">
              <Translate contentKey="CorpvineApp.suUser.reset_date">
                Reset Date
              </Translate>
            </span>
          </dt>
          <dd>
            {suUserEntity.reset_date ? (
              <TextFormat
                value={suUserEntity.reset_date}
                type="date"
                format={APP_DATE_FORMAT}
              />
            ) : null}
          </dd>*/}

        </dl>
        <Button
          tag={Link}
          to="/su-user"
          replace
          color="info"
          data-cy="entityDetailsBackButton"
        >
          <FontAwesomeIcon icon="arrow-left" />{" "}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button
          tag={Link}
          to={`/su-user/${suUserEntity.id}/edit`}
          replace
          color="primary"
        >
          <FontAwesomeIcon icon="pencil-alt" />{" "}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default SuUserDetail;
