import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Table } from "reactstrap";
import {
  JhiItemCount,
  JhiPagination,
  TextFormat,
  Translate,
  getSortState,
} from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import { APP_DATE_FORMAT } from "app/config/constants";
import {
  ASC,
  DESC,
  ITEMS_PER_PAGE,
  SORT,
} from "app/shared/util/pagination.constants";
import { overridePaginationStateWithQueryParams } from "app/shared/util/entity-utils";
import { useAppDispatch, useAppSelector } from "app/config/store";

import { getAllEntitiesList } from "./su-user.reducer";

export const SuUser = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(
      getSortState(pageLocation, ITEMS_PER_PAGE, "id"),
      pageLocation.search,
    ),
  );

  const suUserList = useAppSelector((state) => state.suUser.entities);
  const loading = useAppSelector((state) => state.suUser.loading);
  const totalItems = useAppSelector((state) => state.suUser.totalItems);

  const getAllEntities = () => {
    dispatch(
      getAllEntitiesList({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get("page");
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(",");
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = (p) => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = (currentPage) =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    }
    return order === ASC ? faSortUp : faSortDown;
  };

  return (
    <div>
      <h2 id="su-user-heading" data-cy="SuUserHeading">
        <Translate contentKey="CorpvineApp.suUser.home.title">
          Su User
        </Translate>
        <div className="d-flex justify-content-end">
          <Button
            className="me-2"
            color="info"
            onClick={handleSyncList}
            disabled={loading}
          >
            <FontAwesomeIcon icon="sync" spin={loading} />{" "}
            <Translate contentKey="CorpvineApp.suUser.home.refreshListLabel">
              Refresh List
            </Translate>
          </Button>
          <Link
            to="/su-user/new"
            className="btn btn-primary jh-create-entity"
            id="jh-create-entity"
            data-cy="entityCreateButton"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="CorpvineApp.suUser.home.createLabel">
              Create new SuUser
            </Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {suUserList && suUserList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort("id")}>
                  <Translate contentKey="CorpvineApp.suUser.id">ID</Translate>{" "}
                  <FontAwesomeIcon icon={getSortIconByFieldName("id")} />
                </th>
                <th className="hand" onClick={sort("first_name")}>
                  <Translate contentKey="CorpvineApp.suUser.first_name">
                    First Name
                  </Translate>{" "}
                  <FontAwesomeIcon
                    icon={getSortIconByFieldName("first_name")}
                  />
                </th>
                <th className="hand" onClick={sort("last_name")}>
                  <Translate contentKey="CorpvineApp.suUser.last_name">
                    Last Name
                  </Translate>{" "}
                  <FontAwesomeIcon icon={getSortIconByFieldName("last_name")} />
                </th>
                <th className="hand" onClick={sort("login")}>
                  <Translate contentKey="CorpvineApp.suUser.login">
                    Login
                  </Translate>{" "}
                  <FontAwesomeIcon icon={getSortIconByFieldName("login")} />
                </th>
                <th className="hand" onClick={sort("email")}>
                <Translate contentKey="CorpvineApp.suUser.email">
                    Email
                  </Translate>{" "}
                  <FontAwesomeIcon icon={getSortIconByFieldName("email")} />
                </th>
                {/*<th className="hand" onClick={sort("password_hash")}>
                  <Translate contentKey="CorpvineApp.suUser.password_hash">
                    Password Hash
                  </Translate>{" "}
                  <FontAwesomeIcon
                    icon={getSortIconByFieldName("password_hash")}
                  />
                </th>*/}
                <th className="hand" onClick={sort("activated")}>
                  <Translate contentKey="CorpvineApp.suUser.activated">
                    Activated
                  </Translate>{" "}
                  <FontAwesomeIcon icon={getSortIconByFieldName("activated")} />
                </th>
                {/*
                <th className="hand" onClick={sort("created_by")}>
                  <Translate contentKey="CorpvineApp.suUser.created_by">
                    Created By
                  </Translate>{" "}
                  <FontAwesomeIcon
                    icon={getSortIconByFieldName("created_by")}
                  />
                </th>
                <th className="hand" onClick={sort("created_date")}>
                  <Translate contentKey="CorpvineApp.suUser.created_date">
                    Created Date
                  </Translate>{" "}
                  <FontAwesomeIcon
                    icon={getSortIconByFieldName("created_date")}
                  />
                </th>
                <th className="hand" onClick={sort("last_modified_by")}>
                  <Translate contentKey="CorpvineApp.suUser.last_modified_by">
                    Last Modified By
                  </Translate>{" "}
                  <FontAwesomeIcon
                    icon={getSortIconByFieldName("last_modified_by")}
                  />
                </th>
                <th className="hand" onClick={sort("last_modified_date")}>
                  <Translate contentKey="CorpvineApp.suUser.last_modified_date">
                    Last Modified Date
                  </Translate>{" "}
                  <FontAwesomeIcon
                    icon={getSortIconByFieldName("last_modified_date")}
                  />
                </th>


                <th className="hand" onClick={sort("image_url")}>
                  <Translate contentKey="CorpvineApp.suUser.image_url">
                    Image Url
                  </Translate>{" "}
                  <FontAwesomeIcon icon={getSortIconByFieldName("image_url")} />
                </th>

                <th className="hand" onClick={sort("lang_key")}>
                  <Translate contentKey="CorpvineApp.suUser.lang_key">
                    Lang Key
                  </Translate>{" "}
                  <FontAwesomeIcon icon={getSortIconByFieldName("lang_key")} />
                </th>
                <th className="hand" onClick={sort("activation_key")}>
                  <Translate contentKey="CorpvineApp.suUser.activation_key">
                    Activation Key
                  </Translate>{" "}
                  <FontAwesomeIcon
                    icon={getSortIconByFieldName("activation_key")}
                  />
                </th>
                <th className="hand" onClick={sort("reset_key")}>
                  <Translate contentKey="CorpvineApp.suUser.reset_key">
                    Reset Key
                  </Translate>{" "}
                  <FontAwesomeIcon icon={getSortIconByFieldName("reset_key")} />
                </th>

                <th className="hand" onClick={sort("reset_date")}>
                  <Translate contentKey="CorpvineApp.suUser.reset_date">
                    Reset Date
                  </Translate>{" "}
                  <FontAwesomeIcon
                    icon={getSortIconByFieldName("reset_date")}
                  />
                </th>*/}

                <th />
              </tr>
            </thead>
            <tbody>
              {suUserList.map((suUser, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button
                      tag={Link}
                      to={`/su-user/${suUser.id}`}
                      color="link"
                      size="sm"
                    >
                      {suUser.id}
                    </Button>
                  </td>
                  <td>{suUser.first_name}</td>
                 <td>{suUser.last_name}</td>
                  <td>{suUser.login}</td>
                  <td>{suUser.email}</td>
                 {/*<td>{suUser.password_hash}</td>*/}
                  <td>{suUser.activated}</td>
                 {/* <td>{suUser.created_by?(suUser.created_by.login):("")}</td>
                 <td>{suUser.created_by}</td>
                 <td>{suUser.created_by ? suUser.created_by.login : ''}</td>

                  <td>{suUser.created_date ? (
                   <TextFormat
                          type="date"
                          value={suUser.created_date}
                          format={APP_DATE_FORMAT}
                        />
                      ) : null}
                    </td>
                    <td>{suUser.last_modified_by?(suUser.last_modified_by.login):("")}</td>
                      <td>{suUser.last_modified_date ? (
                       <TextFormat
                            type="date"
                            value={suUser.last_modified_date}
                            format={APP_DATE_FORMAT}
                          />
                        ) : null}
                      </td>

                  {/*
                  <td>{suUser.image_url}</td>

                  <td>{suUser.lang_key}</td>
                  <td>{suUser.activation_key}</td>
                  <td>{suUser.reset_key}</td>

                  <td>
                    {suUser.reset_date ? (
                      <TextFormat
                        type="date"
                        value={suUser.reset_date}
                        format={APP_DATE_FORMAT}
                      />
                    ) : null}
                  </td>*/}

                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        tag={Link}
                        to={`/su-user/${suUser.id}`}
                        color="info"
                        size="sm"
                        data-cy="entityDetailsButton"
                      >
                        <FontAwesomeIcon icon="eye" />{" "}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">
                            View
                          </Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/su-user/${suUser.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{" "}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">
                            Edit
                          </Translate>
                        </span>
                      </Button>
                      {/*<Button
                        onClick={() =>
                          (window.location.href = `/su-user/${suUser.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`)
                        }
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{" "}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">
                            Delete
                          </Translate>
                        </span>
                      </Button>*/}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="CorpvineApp.suUser.home.notFound">
                No Su Users found
              </Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={suUserList && suUserList.length > 0 ? "" : "d-none"}>
          <div className="justify-content-center d-flex">
            <JhiItemCount
              page={paginationState.activePage}
              total={totalItems}
              itemsPerPage={paginationState.itemsPerPage}
              i18nEnabled
            />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default SuUser;
