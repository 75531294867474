import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import {
  Translate,
  ValidatedField,
  ValidatedForm,
  translate,
  isNumber,
} from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  convertDateTimeFromServer,
  convertDateTimeToServer,
  displayDefaultDateTime,
} from "app/shared/util/date-utils";
import { useAppDispatch, useAppSelector } from "app/config/store";
import {getEntities as getSuUsers} from "app/entities/su-user/su-user.reducer";
import { ISuUser } from "app/shared/model/su-user.model";
import {
  createEntity,
  getEntity,
  reset,
  updateEntity,
} from "./su-user.reducer";
import { DROP_DOWN_LIMIT } from "app/shared/util/pagination.constants";
import { isEmail } from 'react-jhipster';
export const SuUserUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<"id">();
  const isNew = id === undefined;

  const suUserEntity = useAppSelector((state) => state.suUser.entity);
  const loading = useAppSelector((state) => state.suUser.loading);
  const updating = useAppSelector((state) => state.suUser.updating);
  const updateSuccess = useAppSelector((state) => state.suUser.updateSuccess);
  const suUsers = useAppSelector((state) => state.suUser.entities);

  const handleClose = () => {
    navigate(`/su-user${location.search}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

     dispatch(getSuUsers({size:DROP_DOWN_LIMIT,
               sort: 'login,asc'}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = (values) => {
    if (values.id !== undefined && typeof values.id !== "number") {
      values.id = Number(values.id);
    }
    if (
      values.activated !== undefined &&
      typeof values.activated !== "number"
    ) {
      values.activated = Number(values.activated);
    }
    values.created_date = convertDateTimeToServer(values.created_date);
    values.reset_date = convertDateTimeToServer(values.reset_date);
    values.last_modified_date = convertDateTimeToServer(
      values.last_modified_date,
    );

    const entity = {
      ...suUserEntity,
      ...values,
    last_modified_by: suUsers.find(
      (it) => it.id.toString() === values.last_modified_by?.toString(),
    ),
    created_by: suUsers.find(

      (it) => it.id.toString() === values.created_by?.toString(),
    ),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          created_date: displayDefaultDateTime(),
          reset_date: displayDefaultDateTime(),
          last_modified_date: displayDefaultDateTime(),
          modified_by: suUserEntity?.created_by?.id,
        }
      : {
          ...suUserEntity,
          created_date: convertDateTimeFromServer(suUserEntity.created_date),
          reset_date: convertDateTimeFromServer(suUserEntity.reset_date),
          last_modified_date: convertDateTimeFromServer(
            suUserEntity.last_modified_date,
          ),
          created_by: suUserEntity?.created_by?.id,
          password_hash: ""

        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2
            id="CorpvineApp.suUser.home.createOrEditLabel"
            data-cy="SuUserCreateUpdateHeading"
          >
            <Translate contentKey="CorpvineApp.suUser.home.createOrEditLabel">
              Create or edit a SuUser
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm
              defaultValues={defaultValues()}
              onSubmit={saveEntity}
            >
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="su-user-id"
                  label={translate("global.field.id")}
                  validate={{ required: true }}
                />
              ) : null}
           <ValidatedField
              label={translate("CorpvineApp.suUser.first_name")}
              id="su-user-first_name"
              name="first_name"
              data-cy="first_name"
              type="text"
              required
            />
            <ValidatedField
              label={translate("CorpvineApp.suUser.last_name")}
              id="su-user-last_name"
              name="last_name"
              data-cy="last_name"
              type="text"
              required
            />
              <ValidatedField
                label={translate("CorpvineApp.suUser.login")}
                id="su-user-login"
                name="login"
                data-cy="login"
                type="text"
                validate={{
                  required: {
                    value: true,
                    message: translate("entity.validation.required"),
                  },
                }}
                onChange={(event) => {
                  const input = event.target;
                  input.value = input.value.trim();
                }}
              />
              <ValidatedField
                  label={translate("CorpvineApp.suUser.email")}
                  id="su-user-email"
                  name="email"
                  data-cy="email"
                  type="text"
                  required
                  onChange={(event) => {
                    const input = event.target;
                    input.value = input.value.trim();
                  }}
              />
            {/* <ValidatedField
                label={translate("CorpvineApp.suUser.password_hash")}
                id="su-user-password_hash"
                name="password_hash"
                data-cy="password_hash"
                type="password"
                validate={{
                  required: "Password is required.", // Required validation
                  minLength: {
                    value: 8,
                    message: "Password must be at least 8 characters.",
                  },
                  maxLength: {
                    value: 15,
                    message: "Password must not exceed 15 characters.",
                  },
                }}
              />*/}


              <ValidatedField
                label={translate("CorpvineApp.suUser.password_hash")}
                id="su-user-password_hash"
                name="password_hash"
                data-cy="password_hash"
                type="password"
                validate={
                  isNew
                    ? {
                        required: "Password is required.", // Mandatory for new users
                        minLength: {
                          value: 8,
                          message: "Password must be at least 8 characters.",
                        },
                        maxLength: {
                          value: 15,
                          message: "Password must not exceed 15 characters.",
                        },
                      }
                    : {
                        minLength: {
                          value: 8,
                          message: "Password must be at least 8 characters.", // Optional for updates
                        },
                        maxLength: {
                          value: 15,
                          message: "Password must not exceed 15 characters.",
                        },
                      }
                }
                onChange={(event) => {
                  const input = event.target;
                  input.value = input.value.trim();
                }}
              />

              <ValidatedField
                label={translate("CorpvineApp.suUser.activated")}
                id="su-user-activated"
                name="activated"
                data-cy="activated"
                placeholder="1-Active or 0-Inactive"
                type="text"
                 validate={{
                     required: {
                         value: true,
                         message: translate("entity.validation.required"),
                     },
                          min: {
                            value: 0,
                            message: translate("entity.validation.min", { min: 0 }),
                          },
                          max: {
                            value: 1,
                            message: translate("entity.validation.max", { max: 1 }),
                          },
                      validate: (v) =>
                        isNumber(v) || translate("entity.validation.number"),
                    }}
              />
              {isNew
                  ?(
              <ValidatedField
                  label={translate("CorpvineApp.suUser.created_by")}
                  id="su-user-created_by"
                  name="created_by"
                  data-cy="created_by"
                  type="select"
                  required
                >
                <option value="" key="0" />
                {suUsers
                  ? suUsers.map((otherEntity) => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.login}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              ):null
          }
            {!isNew
              ?(
              <ValidatedField
                  label={translate("CorpvineApp.suUser.last_modified_by")}
                  id="su-user-last_modified_by"
                  name="last_modified_by"
                  data-cy="last_modified_by"
                  type="select"
                  required
                >
                <option value="" key="0" />
                    {suUsers
                      ? suUsers.map((otherEntity) => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.login}
                          </option>
                        ))
                      : null}
                  </ValidatedField>
                  ):null
                  }

          {/*
              <ValidatedField
                      label={translate("CorpvineApp.suUser.created_date")}
                      id="su-user-created_date"
                      name="created_date"
                      data-cy="created_date"
                      type="datetime-local"
                      placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                  label={translate("CorpvineApp.suUser.last_modified_date")}
                  id="su-user-last_modified_date"
                  name="last_modified_date"
                  data-cy="last_modified_date"
                  type="datetime-local"
                  placeholder="YYYY-MM-DD HH:mm"
              />

              <ValidatedField
                label={translate("CorpvineApp.suUser.image_url")}
                id="su-user-image_url"
                name="image_url"
                data-cy="image_url"
                type="text"
              />

              <ValidatedField
                label={translate("CorpvineApp.suUser.lang_key")}
                id="su-user-lang_key"
                name="lang_key"
                data-cy="lang_key"
                type="text"
              />
              <ValidatedField
                label={translate("CorpvineApp.suUser.activation_key")}
                id="su-user-activation_key"
                name="activation_key"
                data-cy="activation_key"
                type="text"
              />
              <ValidatedField
                label={translate("CorpvineApp.suUser.reset_key")}
                id="su-user-reset_key"
                name="reset_key"
                data-cy="reset_key"
                type="text"
              />

              <ValidatedField
                label={translate("CorpvineApp.suUser.reset_date")}
                id="su-user-reset_date"
                name="reset_date"
                data-cy="reset_date"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />*/}

              <Button
                tag={Link}
                id="cancel-save"
                data-cy="entityCreateCancelButton"
                to="/su-user"
                replace
                color="info"
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button
                color="primary"
                id="save-entity"
                data-cy="entityCreateSaveButton"
                type="submit"
                disabled={updating}
              >
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default SuUserUpdate;
