import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import {
  Translate,
  ValidatedField,
  ValidatedForm,
  translate,
  isNumber,
} from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  convertDateTimeFromServer,
  convertDateTimeToServer,
  displayDefaultDateTime,
} from "app/shared/util/date-utils";
import { useAppDispatch, useAppSelector } from "app/config/store";
import {ISuUser} from "app/shared/model/su-user.model";
import {getEntities as getSuUsers} from "app/entities/su-user/su-user.reducer";
//import { ISuUser } from "app/shared/model/su-user.model";

import {
  createEntity,
  getEntity,
  reset,
  updateEntity,
} from "./organisation.reducer";
import { DROP_DOWN_LIMIT } from "app/shared/util/pagination.constants";


export const OrganisationUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<"id">();
  const isNew = id === undefined;
  const suUsers = useAppSelector((state) => state.suUser.entities);

  const organisationEntity = useAppSelector(
    (state) => state.organisation.entity,
  );
  const loading = useAppSelector((state) => state.organisation.loading);
  const updating = useAppSelector((state) => state.organisation.updating);
  const updateSuccess = useAppSelector(
    (state) => state.organisation.updateSuccess,
  );

  const handleClose = () => {
    navigate("/organisation");
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getSuUsers({size:DROP_DOWN_LIMIT,
          sort: 'login,asc'}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = (values) => {
    if (values.id !== undefined && typeof values.id !== "number") {
      values.id = Number(values.id);
    }
    if (
      values.is_active !== undefined &&
      typeof values.is_active !== "number"
    ) {
      values.is_active = Number(values.is_active);
    }
    values.created_date = convertDateTimeToServer(values.created_date);
    values.modified_date = convertDateTimeToServer(values.modified_date);

    const entity = {
      ...organisationEntity,
      ...values,
      created_by: suUsers.find(
              (it) => it.id.toString() === values.created_by.toString()
            ),
            modified_by: isNew?(suUsers.find(
              (it) => it.id.toString() === values.created_by.toString()
            ))
            :(suUsers.find(
              (it) => it.id.toString() === values.modified_by.toString()
            )),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          created_date: displayDefaultDateTime(),
          modified_date: displayDefaultDateTime(),
          modified_by: organisationEntity?.created_by?.id,

        }
      : {
          ...organisationEntity,
          created_date: convertDateTimeFromServer(
            organisationEntity.created_date,
          ),
          modified_date: displayDefaultDateTime(
            //organisationEntity.modified_date,
          ),
          created_by: organisationEntity?.created_by?.id,

        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2
            id="CorpvineApp.organisation.home.createOrEditLabel"
            data-cy="OrganisationCreateUpdateHeading"
          >
            <Translate contentKey="CorpvineApp.organisation.home.createOrEditLabel">
              Create or edit a Organisation
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm
              defaultValues={defaultValues()}
              onSubmit={saveEntity}
            >
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="organisation-id"
                  label={translate("global.field.id")}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate("CorpvineApp.organisation.org_code")}
                id="organisation-org_code"
                name="org_code"
                data-cy="org_code"
                type="text"
                required
              />
              <ValidatedField
                label={translate("CorpvineApp.organisation.org_name")}
                id="organisation-org_name"
                name="org_name"
                data-cy="org_name"
                type="text"
                required
              />
              <ValidatedField
                label={translate("CorpvineApp.organisation.org_description")}
                id="organisation-org_description"
                name="org_description"
                data-cy="org_description"
                type="text"
              />
              <ValidatedField
                label={translate("CorpvineApp.organisation.is_active")}
                id="organisation-is_active"
                name="is_active"
                data-cy="is_active"
                placeholder="1-Active or 0-Inactive"
                type="text"
                 validate={{
                     required: {
                         value: true,
                         message: translate("entity.validation.required"),
                    },
                                  min: {
                                    value: 0,
                                    message: translate("entity.validation.min", { min: 0 }),
                                  },
                                  max: {
                                    value: 1,
                                    message: translate("entity.validation.max", { max: 1 }),
                                  },
                                  validate: (v) =>
                                    isNumber(v) || translate("entity.validation.number"),
                                }}
                            />
                            {isNew
                            ? (
                            <ValidatedField
                              id="organisation-created_by"
                              name="created_by"
                              data-cy="created_by"
                              label={translate(
                                "CorpvineApp.organisation.created_by"
                              )}
                              type="select"
                              required
                            >
                              <option value="" key="0" />
                              {suUsers
                                ? suUsers.map((otherEntity) => (
                                    <option value={otherEntity.id} key={otherEntity.id}>
                                      {otherEntity.login}
                                    </option>
                                  ))
                                : null}
                            </ValidatedField>
                            ) : null
                          }
                            {!isNew
                            ? (
                            <ValidatedField
                            id="organisation-modified_by"
                             name="modified_by"
                             data-cy="modified_by"

                              label={translate(
                                "CorpvineApp.organisation.modified_by"
                              )}
                              type="select"
                              required
                            >
                              <option value="" key="0" />
                              {suUsers
                                ? suUsers.map((otherEntity) => (
                                    <option value={otherEntity.id} key={otherEntity.id}>
                                      {otherEntity.login}
                                    </option>
                                  ))
                                : null}
                            </ValidatedField>
                             ) : null
                            }
              {/*<ValidatedField
                label={translate("CorpvineApp.organisation.created_date")}
                id="organisation-created_date"
                name="created_date"
                data-cy="created_date"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate("CorpvineApp.organisation.modified_date")}
                id="organisation-modified_date"
                name="modified_date"
                data-cy="modified_date"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />*/}
              <Button
                tag={Link}
                id="cancel-save"
                data-cy="entityCreateCancelButton"
                to="/organisation"
                replace
                color="info"
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button
                color="primary"
                id="save-entity"
                data-cy="entityCreateSaveButton"
                type="submit"
                disabled={updating}
              >
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default OrganisationUpdate;
