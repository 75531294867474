import dayjs from "dayjs";
import { ISuUser } from "app/shared/model/su-user.model";


export interface IOrganisation {
  id?: number;
  org_code?: string | null;
  org_name?: string | null;
  org_description?: string | null;
  is_active?: number;
  created_by?: ISuUser;
  modified_by?: ISuUser;
  created_date?: dayjs.Dayjs | null;
  modified_date?: dayjs.Dayjs | null;
}

export const defaultValue: Readonly<IOrganisation> = {};
