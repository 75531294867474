import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { TextFormat, Translate } from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { APP_DATE_FORMAT } from "app/config/constants";
import { useAppDispatch, useAppSelector } from "app/config/store";

import { getEntity } from "./division.reducer";

export const DivisionDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<"id">();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const divisionEntity = useAppSelector((state) => state.division.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="divisionDetailsHeading">
          <Translate contentKey="CorpvineApp.division.detail.title">
            Division
          </Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{divisionEntity.id}</dd>

          <dt>
          <Translate contentKey="CorpvineApp.division.organisation">
            Organisation
           </Translate>
           </dt>
           <dd>{divisionEntity.organisation ? divisionEntity.organisation.org_code : ""} </dd>

          <dt>
            <span id="division_code">
              <Translate contentKey="CorpvineApp.division.division_code">
                Division Code
              </Translate>
            </span>
          </dt>
          <dd>{divisionEntity.division_code}</dd>
          <dt>
            <span id="division_name">
              <Translate contentKey="CorpvineApp.division.division_name">
                Division Name
              </Translate>
            </span>
          </dt>
          <dd>{divisionEntity.division_name}</dd>
          <dt>
            <span id="division_description">
              <Translate contentKey="CorpvineApp.division.division_description">
                Division Description
              </Translate>
            </span>
          </dt>
          <dd>{divisionEntity.division_description}</dd>
          <dt>
            <span id="is_active">
              <Translate contentKey="CorpvineApp.division.is_active">
                Is Active
              </Translate>
            </span>
          </dt>
          <dd>{divisionEntity.is_active}</dd>

           <dt>
                                <Translate contentKey="CorpvineApp.division.createdby">
                                  Createdby
                                </Translate>
                              </dt>
                              <dd>{divisionEntity.createdby ? divisionEntity.createdby.login : ""}</dd>
           <dt>
                       <span id="created_date">
                         <Translate contentKey="CorpvineApp.division.created_date">
                           Created Date
                         </Translate>
                       </span>
                     </dt>
                     <dd>
                       {divisionEntity.created_date ? (
                         <TextFormat
                           value={divisionEntity.created_date}
                           type="date"
                           format={APP_DATE_FORMAT}
                         />
                       ) : null}
                     </dd>
                    <dt>
                      <Translate contentKey="CorpvineApp.division.modifiedby">
                        Modifiedby
                      </Translate>
                    </dt>
                    <dd>
                      {divisionEntity.modifiedby ? divisionEntity.modifiedby.login : ""}
                    </dd>

          <dt>
            <span id="modified_date">
              <Translate contentKey="CorpvineApp.division.modified_date">
                Modified Date
              </Translate>
            </span>
          </dt>
          <dd>
            {divisionEntity.modified_date ? (
              <TextFormat
                value={divisionEntity.modified_date}
                type="date"
                format={APP_DATE_FORMAT}
              />
            ) : null}
          </dd>
         {/* <dt>
            <Translate contentKey="CorpvineApp.division.organisation">
              Organisation
            </Translate>
          </dt>
          <dd>
            {divisionEntity.organisation ? divisionEntity.organisation.org_code : ""}
          </dd>*/}


        </dl>
        <Button
          tag={Link}
          to="/division"
          replace
          color="info"
          data-cy="entityDetailsBackButton"
        >
          <FontAwesomeIcon icon="arrow-left" />{" "}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button
          tag={Link}
          to={`/division/${divisionEntity.id}/edit`}
          replace
          color="primary"
        >
          <FontAwesomeIcon icon="pencil-alt" />{" "}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default DivisionDetail;
