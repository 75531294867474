import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Table } from "reactstrap";
import { TextFormat, Translate, getSortState } from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import { APP_DATE_FORMAT } from "app/config/constants";
import { ASC, DESC } from "app/shared/util/pagination.constants";
import { overridePaginationStateWithQueryParams } from "app/shared/util/entity-utils";
import { useAppDispatch, useAppSelector } from "app/config/store";

import { getAllEntitiesList } from "./organisation.reducer";

export const Organisation = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [sortState, setSortState] = useState(
    overridePaginationStateWithQueryParams(
      getSortState(pageLocation, parseInt("id")),
      pageLocation.search,
    ),
  );

  const organisationList = useAppSelector(
    (state) => state.organisation.entities,
  );
  const loading = useAppSelector((state) => state.organisation.loading);

  const getAllEntities = () => {
    dispatch(
      getAllEntitiesList({
        sort: `${sortState.sort},${sortState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?sort=${sortState.sort},${sortState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [sortState.order, sortState.sort]);

  const sort = (p) => () => {
    setSortState({
      ...sortState,
      order: sortState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = sortState.sort;
    const order = sortState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    }
    return order === ASC ? faSortUp : faSortDown;
  };

  return (
    <div>
      <h2 id="organisation-heading" data-cy="OrganisationHeading">
        <Translate contentKey="CorpvineApp.organisation.home.title">
          Organisations
        </Translate>
        <div className="d-flex justify-content-end">
          <Button
            className="me-2"
            color="info"
            onClick={handleSyncList}
            disabled={loading}
          >
            <FontAwesomeIcon icon="sync" spin={loading} />{" "}
            <Translate contentKey="CorpvineApp.organisation.home.refreshListLabel">
              Refresh List
            </Translate>
          </Button>
          <Link
            to="/organisation/new"
            className="btn btn-primary jh-create-entity"
            id="jh-create-entity"
            data-cy="entityCreateButton"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="CorpvineApp.organisation.home.createLabel">
              Create new Organisation
            </Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {organisationList && organisationList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort("id")}>
                  <Translate contentKey="CorpvineApp.organisation.id">
                    ID
                  </Translate>{" "}
                  <FontAwesomeIcon icon={getSortIconByFieldName("id")} />
                </th>
                <th className="hand" onClick={sort("org_code")}>
                  <Translate contentKey="CorpvineApp.organisation.org_code">
                    Org Code
                  </Translate>{" "}
                  <FontAwesomeIcon icon={getSortIconByFieldName("org_code")} />
                </th>
                <th className="hand" onClick={sort("org_name")}>
                  <Translate contentKey="CorpvineApp.organisation.org_name">
                    Org Name
                  </Translate>{" "}
                  <FontAwesomeIcon icon={getSortIconByFieldName("org_name")} />
                </th>
                <th className="hand" onClick={sort("org_description")}>
                  <Translate contentKey="CorpvineApp.organisation.org_description">
                    Org Description
                  </Translate>{" "}
                  <FontAwesomeIcon
                    icon={getSortIconByFieldName("org_description")}
                  />
                </th>
                <th className="hand" onClick={sort("is_active")}>
                  <Translate contentKey="CorpvineApp.organisation.is_active">
                    Is Active
                  </Translate>{" "}
                  <FontAwesomeIcon icon={getSortIconByFieldName("is_active")} />
                </th>
                <th>
                  <Translate contentKey="CorpvineApp.organisation.created_by">
                    Created By
                  </Translate>{" "}
                  <FontAwesomeIcon
                    icon={getSortIconByFieldName("created_by")}
                  />
                </th>
                 <th className="hand" onClick={sort("created_date")}>
                                  <Translate contentKey="CorpvineApp.organisation.created_date">
                                    Created Date
                                  </Translate>{" "}
                                  <FontAwesomeIcon
                                    icon={getSortIconByFieldName("created_date")}
                                  />
                                </th>
                <th>
                  <Translate contentKey="CorpvineApp.organisation.modified_by">
                    Modified By
                  </Translate>{" "}
                  <FontAwesomeIcon
                    icon={getSortIconByFieldName("modified_by")}
                  />
                </th>

                <th className="hand" onClick={sort("modified_date")}>
                  <Translate contentKey="CorpvineApp.organisation.modified_date">
                    Modified Date
                  </Translate>{" "}
                  <FontAwesomeIcon
                    icon={getSortIconByFieldName("modified_date")}
                  />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {organisationList.map((organisation, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button
                      tag={Link}
                      to={`/organisation/${organisation.id}`}
                      color="link"
                      size="sm"
                    >
                      {organisation.id}
                    </Button>
                  </td>
                  <td>{organisation.org_code}</td>
                  <td>{organisation.org_name}</td>
                  <td>{organisation.org_description}</td>
                  <td style={{ textAlign: "center" }}>{organisation.is_active}</td>
                  <td>{organisation.created_by ? (organisation.created_by.login) : ("")}</td>
                  <td>{organisation.created_date ? (
                  <TextFormat type="date"value={organisation.created_date}format={APP_DATE_FORMAT}/>) : null}
                  </td>
                  <td>{organisation.modified_by ? (organisation.modified_by.login) : ("")}
                  </td>
                  <td>
                    {organisation.modified_date ? (
                      <TextFormat
                        type="date"
                        value={organisation.modified_date}
                        format={APP_DATE_FORMAT}
                      />
                    ) : null}
                  </td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        tag={Link}
                        to={`/organisation/${organisation.id}`}
                        color="info"
                        size="sm"
                        data-cy="entityDetailsButton"
                      >
                        <FontAwesomeIcon icon="eye" />{" "}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">
                            View
                          </Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/organisation/${organisation.id}/edit`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{" "}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">
                            Edit
                          </Translate>
                        </span>
                      </Button>
                      <Button
                        onClick={() =>
                          (window.location.href = `/organisation/${organisation.id}/delete`)
                        }
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{" "}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">
                            Delete
                          </Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="CorpvineApp.organisation.home.notFound">
                No Organisations found
              </Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Organisation;
