import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Table } from "reactstrap";
import { TextFormat, Translate, getSortState } from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import { APP_DATE_FORMAT } from "app/config/constants";
import { ASC, DESC, ITEMS_PER_PAGE } from "app/shared/util/pagination.constants";
import { overridePaginationStateWithQueryParams } from "app/shared/util/entity-utils";
import { useAppDispatch, useAppSelector } from "app/config/store";

import { getEntities } from "./division.reducer";


export const Division = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [sortState, setSortState] = useState(
    overridePaginationStateWithQueryParams(
          getSortState(pageLocation,ITEMS_PER_PAGE, "id"),
          pageLocation.search,
        ),
  );

  const divisionList = useAppSelector((state) => state.division.entities);
  const loading = useAppSelector((state) => state.division.loading);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        sort: `${sortState.sort},${sortState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?sort=${sortState.sort},${sortState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [sortState.order, sortState.sort]);

  const sort = (p) => () => {
    setSortState({
      ...sortState,
      order: sortState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = sortState.sort;
    const order = sortState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    }
    return order === ASC ? faSortUp : faSortDown;
  };

  return (
    <div>
      <h2 id="division-heading" data-cy="DivisionHeading">
        <Translate contentKey="CorpvineApp.division.home.title">
          Divisions
        </Translate>
        <div className="d-flex justify-content-end">
          <Button
            className="me-2"
            color="info"
            onClick={handleSyncList}
            disabled={loading}
          >
            <FontAwesomeIcon icon="sync" spin={loading} />{" "}
            <Translate contentKey="CorpvineApp.division.home.refreshListLabel">
              Refresh List
            </Translate>
          </Button>
          <Link
            to="/division/new"
            className="btn btn-primary jh-create-entity"
            id="jh-create-entity"
            data-cy="entityCreateButton"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="CorpvineApp.division.home.createLabel">
              Create new Division
            </Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {divisionList && divisionList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort("id")}>
                  <Translate contentKey="CorpvineApp.division.id">ID</Translate>{" "}
                  <FontAwesomeIcon icon={getSortIconByFieldName("id")} />
                </th>
                 <th>
                    <Translate contentKey="CorpvineApp.division.organisation">
                    Organisation
                    </Translate>{" "}
                    <FontAwesomeIcon icon="sort" />
                 </th>
                <th className="hand" onClick={sort("division_code")}>
                  <Translate contentKey="CorpvineApp.division.division_code">
                    Division Code
                  </Translate>{" "}
                  <FontAwesomeIcon
                    icon={getSortIconByFieldName("division_code")}
                  />
                </th>
                <th className="hand" onClick={sort("division_name")}>
                  <Translate contentKey="CorpvineApp.division.division_name">
                    Division Name
                  </Translate>{" "}
                  <FontAwesomeIcon
                    icon={getSortIconByFieldName("division_name")}
                  />
                </th>
                <th className="hand" onClick={sort("division_description")}>
                  <Translate contentKey="CorpvineApp.division.division_description">
                    Division Description
                  </Translate>{" "}
                  <FontAwesomeIcon
                    icon={getSortIconByFieldName("division_description")}
                  />
                </th>
                <th className="hand" onClick={sort("is_active")}>
                  <Translate contentKey="CorpvineApp.division.is_active">
                    Is Active
                  </Translate>{" "}
                  <FontAwesomeIcon icon={getSortIconByFieldName("is_active")} />
                </th>
               <th>
                 <Translate contentKey="CorpvineApp.division.createdby">
                  Createdby
                   </Translate>{" "}
                   <FontAwesomeIcon icon="sort" />
               </th>
               <th className="hand" onClick={sort("created_date")}>
                                   <Translate contentKey="CorpvineApp.division.created_date">
                                   Created Date
                                   </Translate>{" "}
                                    <FontAwesomeIcon
                                    icon={getSortIconByFieldName("created_date")}
                                    />
                                </th>
                <th>
                  <Translate contentKey="CorpvineApp.division.modifiedby">
                    Modifiedby
                  </Translate>{" "}
                  <FontAwesomeIcon icon="sort" />
                </th>

                 <th className="hand" onClick={sort("modified_date")}>
                 <Translate contentKey="CorpvineApp.division.modified_date">
                     Modified Date
                 </Translate>{" "}
                    <FontAwesomeIcon
                   icon={getSortIconByFieldName("modified_date")}
                 />
                  </th>

                <th />
              </tr>
            </thead>
            <tbody>
              {divisionList.map((division, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td style={{ textAlign: "center",verticalAlign:"middle" }}>
                    <Button
                      tag={Link}
                      to={`/division/${division.id}`}
                      color="link"
                      size="sm"
                    >
                      {division.id}
                    </Button>
                  </td>
                  <td style={{ textAlign: "center",verticalAlign:"middle" }}>
                    {division.organisation ? (
                        division.organisation.org_code
                    ) : (
                      ""
                    )}
                  </td>
                  <td style={{ textAlign: "center",verticalAlign:"middle"  }}>{division.division_code}</td>
                  <td>{division.division_name}</td>
                  <td>{division.division_description}</td>
                  <td style={{ textAlign: "center",verticalAlign:"middle" }}>{division.is_active}</td>
                  <td>
                    {division.createdby ? (
                        division.createdby.login
                    ) : (
                      ""
                    )}
                  </td>
                  <td>
                                           {division.created_date ? (
                                             <TextFormat
                                               type="date"
                                               value={division.created_date}
                                               format={APP_DATE_FORMAT}
                                             />
                                           ) : null}
                                         </td>
                  <td>
                    {division.modifiedby ? (
                        division.modifiedby.login
                    ) : (
                      ""
                    )}
                  </td>

                  <td>
                    {division.modified_date ? (
                      <TextFormat
                        type="date"
                        value={division.modified_date}
                        format={APP_DATE_FORMAT}
                      />
                    ) : null}
                  </td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        tag={Link}
                        to={`/division/${division.id}`}
                        color="info"
                        size="sm"
                        data-cy="entityDetailsButton"
                      >
                        <FontAwesomeIcon icon="eye" />{" "}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">
                            View
                          </Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/division/${division.id}/edit`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{" "}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">
                            Edit
                          </Translate>
                        </span>
                      </Button>
                      {/*<Button
                        onClick={() =>
                          (window.location.href = `/division/${division.id}/delete`)
                        }
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{" "}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">
                            Delete
                          </Translate>
                        </span>
                      </Button>*/}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="CorpvineApp.division.home.notFound">
                No Divisions found
              </Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Division;
