import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import {
  isNumber,
  Translate,
  ValidatedField,
  ValidatedForm,
  translate,
} from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  convertDateTimeFromServer,
  convertDateTimeToServer,
  displayDefaultDateTime,
} from "app/shared/util/date-utils";
import { useAppDispatch, useAppSelector } from "app/config/store";

import {
  createEntity,
  getEntity,
  reset,
  updateEntity,
} from "./country.reducer";

export const CountryUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<"id">();
  const isNew = id === undefined;

  const countryEntity = useAppSelector((state) => state.country.entity);
  const loading = useAppSelector((state) => state.country.loading);
  const updating = useAppSelector((state) => state.country.updating);
  const updateSuccess = useAppSelector((state) => state.country.updateSuccess);

  const handleClose = () => {
    navigate("/country");
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = (values) => {
    if (values.id !== undefined && typeof values.id !== "number") {
      values.id = Number(values.id);
    }
    values.created = convertDateTimeToServer(values.created);
    values.modified = convertDateTimeToServer(values.modified);

    const entity = {
      ...countryEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          created: displayDefaultDateTime(),
          modified: displayDefaultDateTime(),
        }
      : {
          ...countryEntity,
          created: convertDateTimeFromServer(countryEntity.created),
          modified: convertDateTimeFromServer(countryEntity.modified),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2
            id="CorpvineApp.country.home.createOrEditLabel"
            data-cy="CountryCreateUpdateHeading"
          >
            <Translate contentKey="CorpvineApp.country.home.createOrEditLabel">
              Create or edit a Country
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm
              defaultValues={defaultValues()}
              onSubmit={saveEntity}
            >
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="country-id"
                  label={translate("global.field.id")}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate("CorpvineApp.country.country_name")}
                id="country-country_name"
                name="country_name"
                data-cy="country_name"
                type="text"
              />
              <ValidatedField
                label={translate("CorpvineApp.country.country_code")}
                id="country-country_code"
                name="country_code"
                data-cy="country_code"
                type="text"
              />
              <ValidatedField
                label={translate("CorpvineApp.country.country_code2")}
                id="country-country_code2"
                name="country_code2"
                data-cy="country_code2"
                type="text"
              />
              <ValidatedField
                label={translate("CorpvineApp.country.is_active")}
                id="country-is_active"
                name="is_active"
                data-cy="is_active"
                placeholder="1-Active or 0-Inactive"
                type="text"
                 validate={{
                                  required: {
                                    value: true,
                                    message: translate("entity.validation.required"),
                                  },
                                  min: {
                                    value: 0,
                                    message: translate("entity.validation.min", { min: 0 }),
                                  },
                                  max: {
                                    value: 1,
                                    message: translate("entity.validation.max", { max: 1 }),
                                  },
                                  validate: (v) =>
                                    isNumber(v) || translate("entity.validation.number"),
                                }}

              />
              <ValidatedField
                label={translate("CorpvineApp.country.created")}
                id="country-created"
                name="created"
                data-cy="created"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate("CorpvineApp.country.modified")}
                id="country-modified"
                name="modified"
                data-cy="modified"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <Button
                tag={Link}
                id="cancel-save"
                data-cy="entityCreateCancelButton"
                to="/country"
                replace
                color="info"
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button
                color="primary"
                id="save-entity"
                data-cy="entityCreateSaveButton"
                type="submit"
                disabled={updating}
              >
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CountryUpdate;
