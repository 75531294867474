import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { TextFormat, Translate } from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { APP_DATE_FORMAT } from "app/config/constants";
import { useAppDispatch, useAppSelector } from "app/config/store";

import { getEntity } from "./country.reducer";

export const CountryDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<"id">();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const countryEntity = useAppSelector((state) => state.country.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="countryDetailsHeading">
          <Translate contentKey="CorpvineApp.country.detail.title">
            Country
          </Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{countryEntity.id}</dd>
          <dt>
            <span id="country_name">
              <Translate contentKey="CorpvineApp.country.country_name">
                Country Name
              </Translate>
            </span>
          </dt>
          <dd>{countryEntity.country_name}</dd>
          <dt>
            <span id="country_code">
              <Translate contentKey="CorpvineApp.country.country_code">
                Country Code
              </Translate>
            </span>
          </dt>
          <dd>{countryEntity.country_code}</dd>
          <dt>
            <span id="country_code2">
              <Translate contentKey="CorpvineApp.country.country_code2">
                Country Code 2
              </Translate>
            </span>
          </dt>
          <dd>{countryEntity.country_code2}</dd>
          <dt>
            <span id="is_active">
              <Translate contentKey="CorpvineApp.country.is_active">
                Is Active
              </Translate>
            </span>
          </dt>
          <dd>{countryEntity.is_active ? "true" : "false"}</dd>
          <dt>
            <span id="created">
              <Translate contentKey="CorpvineApp.country.created">
                Created
              </Translate>
            </span>
          </dt>
          <dd>
            {countryEntity.created ? (
              <TextFormat
                value={countryEntity.created}
                type="date"
                format={APP_DATE_FORMAT}
              />
            ) : null}
          </dd>
          <dt>
            <span id="modified">
              <Translate contentKey="CorpvineApp.country.modified">
                Modified
              </Translate>
            </span>
          </dt>
          <dd>
            {countryEntity.modified ? (
              <TextFormat
                value={countryEntity.modified}
                type="date"
                format={APP_DATE_FORMAT}
              />
            ) : null}
          </dd>
        </dl>
        <Button
          tag={Link}
          to="/country"
          replace
          color="info"
          data-cy="entityDetailsBackButton"
        >
          <FontAwesomeIcon icon="arrow-left" />{" "}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button
          tag={Link}
          to={`/country/${countryEntity.id}/edit`}
          replace
          color="primary"
        >
          <FontAwesomeIcon icon="pencil-alt" />{" "}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default CountryDetail;
