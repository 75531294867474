import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Table } from "reactstrap";
import { TextFormat, Translate, getSortState } from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import { APP_DATE_FORMAT } from "app/config/constants";
import { ASC, DESC,ITEMS_PER_PAGE } from "app/shared/util/pagination.constants";
import { overridePaginationStateWithQueryParams } from "app/shared/util/entity-utils";
import { useAppDispatch, useAppSelector } from "app/config/store";

import { getEntities } from "./state.reducer";

export const State = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [sortState, setSortState] = useState(
    overridePaginationStateWithQueryParams(
      getSortState(pageLocation,ITEMS_PER_PAGE, "id"),
      pageLocation.search,
    ),
  );

  const stateList = useAppSelector((state) => state.state.entities);
  const loading = useAppSelector((state) => state.state.loading);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        sort: `${sortState.sort},${sortState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?sort=${sortState.sort},${sortState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [sortState.order, sortState.sort]);

  const sort = (p) => () => {
    setSortState({
      ...sortState,
      order: sortState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = sortState.sort;
    const order = sortState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    }
    return order === ASC ? faSortUp : faSortDown;
  };

  return (
    <div>
      <h2 id="state-heading" data-cy="StateHeading">
        <Translate contentKey="CorpvineApp.state.home.title">States</Translate>
        <div className="d-flex justify-content-end">
          <Button
            className="me-2"
            color="info"
            onClick={handleSyncList}
            disabled={loading}
          >
            <FontAwesomeIcon icon="sync" spin={loading} />{" "}
            <Translate contentKey="CorpvineApp.state.home.refreshListLabel">
              Refresh List
            </Translate>
          </Button>
          <Link
            to="/state/new"
            className="btn btn-primary jh-create-entity"
            id="jh-create-entity"
            data-cy="entityCreateButton"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="CorpvineApp.state.home.createLabel">
              Create new State
            </Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {stateList && stateList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort("id")}>
                  <Translate contentKey="CorpvineApp.state.id">ID</Translate>{" "}
                  <FontAwesomeIcon icon={getSortIconByFieldName("id")} />
                </th>
                <th className="hand" onClick={sort("stateName")}>
                  <Translate contentKey="CorpvineApp.state.stateName">
                    State Name
                  </Translate>{" "}
                  <FontAwesomeIcon icon={getSortIconByFieldName("stateName")} />
                </th>
                <th className="hand" onClick={sort("stateCode")}>
                  <Translate contentKey="CorpvineApp.state.stateCode">
                    State Code
                  </Translate>{" "}
                  <FontAwesomeIcon icon={getSortIconByFieldName("stateCode")} />
                </th>
                <th className="hand" onClick={sort("mapPointX")}>
                  <Translate contentKey="CorpvineApp.state.mapPointX">
                    Map Point X
                  </Translate>{" "}
                  <FontAwesomeIcon icon={getSortIconByFieldName("mapPointX")} />
                </th>
                <th className="hand" onClick={sort("mapPointY")}>
                  <Translate contentKey="CorpvineApp.state.mapPointY">
                    Map Point Y
                  </Translate>{" "}
                  <FontAwesomeIcon icon={getSortIconByFieldName("mapPointY")} />
                </th>
                <th className="hand" onClick={sort("isactive")}>
                  <Translate contentKey="CorpvineApp.state.isactive">
                    Isactive
                  </Translate>{" "}
                  <FontAwesomeIcon icon={getSortIconByFieldName("isactive")} />
                </th>
                <th className="hand" onClick={sort("created")}>
                  <Translate contentKey="CorpvineApp.state.created">
                    Created
                  </Translate>{" "}
                  <FontAwesomeIcon icon={getSortIconByFieldName("created")} />
                </th>
                <th className="hand" onClick={sort("modified")}>
                  <Translate contentKey="CorpvineApp.state.modified">
                    Modified
                  </Translate>{" "}
                  <FontAwesomeIcon icon={getSortIconByFieldName("modified")} />
                </th>
                <th>
                  <Translate contentKey="CorpvineApp.state.country">
                    Country
                  </Translate>{" "}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {stateList.map((state, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button
                      tag={Link}
                      to={`/state/${state.id}`}
                      color="link"
                      size="sm"
                    >
                      {state.id}
                    </Button>
                  </td>
                  <td>{state.stateName}</td>
                  <td>{state.stateCode}</td>
                  <td>{state.mapPointX}</td>
                  <td>{state.mapPointY}</td>
                  <td>{state.isactive}</td>
                  <td>
                    {state.created ? (
                      <TextFormat
                        type="date"
                        value={state.created}
                        format={APP_DATE_FORMAT}
                      />
                    ) : null}
                  </td>
                  <td>
                    {state.modified ? (
                      <TextFormat
                        type="date"
                        value={state.modified}
                        format={APP_DATE_FORMAT}
                      />
                    ) : null}
                  </td>
                  <td>
                    {state.country ? (
                      <Link to={`/country/${state.country.id}`}>
                        {state.country.country_name}
                      </Link>
                    ) : (
                      ""
                    )}
                  </td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        tag={Link}
                        to={`/state/${state.id}`}
                        color="info"
                        size="sm"
                        data-cy="entityDetailsButton"
                      >
                        <FontAwesomeIcon icon="eye" />{" "}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">
                            View
                          </Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/state/${state.id}/edit`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{" "}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">
                            Edit
                          </Translate>
                        </span>
                      </Button>
                      <Button
                        onClick={() =>
                          (window.location.href = `/state/${state.id}/delete`)
                        }
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{" "}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">
                            Delete
                          </Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="CorpvineApp.state.home.notFound">
                No States found
              </Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default State;
