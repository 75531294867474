import dayjs from "dayjs";

export interface ICountry {
  id?: number;
  country_name?: string | null;
  country_code?: string | null;
  country_code2?: string | null;
  is_active?: number;
  created?: dayjs.Dayjs | null;
  modified?: dayjs.Dayjs | null;
}

export const defaultValue: Readonly<ICountry> = {
};
