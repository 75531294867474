import axios from "axios";
import { createAsyncThunk, isFulfilled, isPending } from "@reduxjs/toolkit";
import { ASC } from "app/shared/util/pagination.constants";
import { cleanEntity } from "app/shared/util/entity-utils";
import {
  EntityState,
  IQueryParams,
  createEntitySlice,
  serializeAxiosError,
} from "app/shared/reducers/reducer.utils";
import { IState, defaultValue } from "app/shared/model/state.model";

const initialState: EntityState<IState> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

const apiUrl = "api/states";

// Actions

export const getEntities = createAsyncThunk(
  "state/fetch_entity_list",
  async ({ sort }: IQueryParams) => {
    const requestUrl = `${apiUrl}?${sort ? `sort=${sort}&` : ""}cacheBuster=${new Date().getTime()}`;
    return axios.get<IState[]>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const getEntity = createAsyncThunk(
  "state/fetch_entity",
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IState>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const createEntity = createAsyncThunk(
  "state/create_entity",
  async (entity: IState, thunkAPI) => {
    const result = await axios.post<IState>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const updateEntity = createAsyncThunk(
  "state/update_entity",
  async (entity: IState, thunkAPI) => {
    const result = await axios.put<IState>(
      `${apiUrl}/${entity.id}`,
      cleanEntity(entity),
    );
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const partialUpdateEntity = createAsyncThunk(
  "state/partial_update_entity",
  async (entity: IState, thunkAPI) => {
    const result = await axios.patch<IState>(
      `${apiUrl}/${entity.id}`,
      cleanEntity(entity),
    );
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const deleteEntity = createAsyncThunk(
  "state/delete_entity",
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IState>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

// slice

export const StateSlice = createEntitySlice({
  name: "state",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, (state) => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data.sort((a, b) => {
            if (!action.meta?.arg?.sort) {
              return 1;
            }
            const order = action.meta.arg.sort.split(",")[1];
            const predicate = action.meta.arg.sort.split(",")[0];
            return order === ASC
              ? a[predicate] < b[predicate]
                ? -1
                : 1
              : b[predicate] < a[predicate]
                ? -1
                : 1;
          }),
        };
      })
      .addMatcher(
        isFulfilled(createEntity, updateEntity, partialUpdateEntity),
        (state, action) => {
          state.updating = false;
          state.loading = false;
          state.updateSuccess = true;
          state.entity = action.payload.data;
        },
      )
      .addMatcher(isPending(getEntities, getEntity), (state) => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(
        isPending(
          createEntity,
          updateEntity,
          partialUpdateEntity,
          deleteEntity,
        ),
        (state) => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.updating = true;
        },
      );
  },
});

export const { reset } = StateSlice.actions;

// Reducer
export default StateSlice.reducer;
