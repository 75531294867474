import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import {
  Translate,
  ValidatedField,
  ValidatedForm,
  translate,
} from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  convertDateTimeFromServer,
  convertDateTimeToServer,
  displayDefaultDateTime,
} from "app/shared/util/date-utils";
import { useAppDispatch, useAppSelector } from "app/config/store";

import { getEntities as getCountries } from "app/entities/country/country.reducer";
import { createEntity, getEntity, reset, updateEntity } from "./state.reducer";

export const StateUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<"id">();
  const isNew = id === undefined;

  const countries = useAppSelector((state) => state.country.entities);
  const stateEntity = useAppSelector((state) => state.state.entity);
  const loading = useAppSelector((state) => state.state.loading);
  const updating = useAppSelector((state) => state.state.updating);
  const updateSuccess = useAppSelector((state) => state.state.updateSuccess);

  const handleClose = () => {
    navigate("/state");
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getCountries({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = (values) => {
    if (values.id !== undefined && typeof values.id !== "number") {
      values.id = Number(values.id);
    }
    if (
      values.mapPointX !== undefined &&
      typeof values.mapPointX !== "number"
    ) {
      values.mapPointX = Number(values.mapPointX);
    }
    if (
      values.mapPointY !== undefined &&
      typeof values.mapPointY !== "number"
    ) {
      values.mapPointY = Number(values.mapPointY);
    }
    if (values.isactive !== undefined && typeof values.isactive !== "number") {
      values.isactive = Number(values.isactive);
    }
    values.created = convertDateTimeToServer(values.created);
    values.modified = convertDateTimeToServer(values.modified);

    const entity = {
      ...stateEntity,
      ...values,
      country: countries.find(
        (it) => it.id.toString() === values.country?.toString(),
      ),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          created: displayDefaultDateTime(),
          modified: displayDefaultDateTime(),
        }
      : {
          ...stateEntity,
          created: convertDateTimeFromServer(stateEntity.created),
          modified: convertDateTimeFromServer(stateEntity.modified),
          country: stateEntity?.country?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2
            id="CorpvineApp.state.home.createOrEditLabel"
            data-cy="StateCreateUpdateHeading"
          >
            <Translate contentKey="CorpvineApp.state.home.createOrEditLabel">
              Create or edit a State
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm
              defaultValues={defaultValues()}
              onSubmit={saveEntity}
            >
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="state-id"
                  label={translate("global.field.id")}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate("CorpvineApp.state.stateName")}
                id="state-stateName"
                name="stateName"
                data-cy="stateName"
                type="text"
              />
              <ValidatedField
                label={translate("CorpvineApp.state.stateCode")}
                id="state-stateCode"
                name="stateCode"
                data-cy="stateCode"
                type="text"
              />
              <ValidatedField
                label={translate("CorpvineApp.state.mapPointX")}
                id="state-mapPointX"
                name="mapPointX"
                data-cy="mapPointX"
                type="text"
              />
              <ValidatedField
                label={translate("CorpvineApp.state.mapPointY")}
                id="state-mapPointY"
                name="mapPointY"
                data-cy="mapPointY"
                type="text"
              />
              <ValidatedField
                label={translate("CorpvineApp.state.isactive")}
                id="state-isactive"
                name="isactive"
                data-cy="isactive"
                type="text"
              />
              <ValidatedField
                label={translate("CorpvineApp.state.created")}
                id="state-created"
                name="created"
                data-cy="created"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate("CorpvineApp.state.modified")}
                id="state-modified"
                name="modified"
                data-cy="modified"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                id="state-country"
                name="country"
                data-cy="country"
                label={translate("CorpvineApp.state.country")}
                type="select"
              >
                <option value="" key="0" />
                {countries
                  ? countries.map((otherEntity) => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.country_name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button
                tag={Link}
                id="cancel-save"
                data-cy="entityCreateCancelButton"
                to="/state"
                replace
                color="info"
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button
                color="primary"
                id="save-entity"
                data-cy="entityCreateSaveButton"
                type="submit"
                disabled={updating}
              >
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default StateUpdate;
