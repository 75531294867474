import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { TextFormat, Translate } from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { APP_DATE_FORMAT } from "app/config/constants";
import { useAppDispatch, useAppSelector } from "app/config/store";

import { getEntity } from "./accounts.reducer";

export const AccountsDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<"id">();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const accountsEntity = useAppSelector((state) => state.accounts.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="accountsDetailsHeading">
          <Translate contentKey="CorpvineApp.accounts.detail.title">
            Accounts
          </Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{accountsEntity.id}</dd>
          <dt>
            <span id="clientName">
              <Translate contentKey="CorpvineApp.accounts.clientName">
                Client Name
              </Translate>
            </span>
          </dt>
          <dd>{accountsEntity.clientName}</dd>
          <dt>
            <span id="email">
              <Translate contentKey="CorpvineApp.accounts.email">
                Email
              </Translate>
            </span>
          </dt>
          <dd>{accountsEntity.email}</dd>
          <dt>
            <span id="phoneNo">
              <Translate contentKey="CorpvineApp.accounts.phoneNo">
                Phone No
              </Translate>
            </span>
          </dt>
          <dd>{accountsEntity.phoneNo}</dd>
          <dt>
            <span id="poc">
              <Translate contentKey="CorpvineApp.accounts.poc">Poc</Translate>
            </span>
          </dt>
          <dd>{accountsEntity.poc}</dd>
          <dt>
            <span id="address">
              <Translate contentKey="CorpvineApp.accounts.address">
                Address
              </Translate>
            </span>
          </dt>
          <dd>{accountsEntity.address}</dd>
          <dt>
            <span id="fax">
              <Translate contentKey="CorpvineApp.accounts.fax">Fax</Translate>
            </span>
          </dt>
          <dd>{accountsEntity.fax}</dd>
          <dt>
            <span id="isActive">
              <Translate contentKey="CorpvineApp.accounts.isActive">
                Is Active
              </Translate>
            </span>
          </dt>
          <dd>{accountsEntity.isActive}</dd>
          <dt>
            <span id="created">
              <Translate contentKey="CorpvineApp.accounts.created">
                Created
              </Translate>
            </span>
          </dt>
          <dd>
            {accountsEntity.created ? (
              <TextFormat
                value={accountsEntity.created}
                type="date"
                format={APP_DATE_FORMAT}
              />
            ) : null}
          </dd>
          <dt>
            <span id="modified">
              <Translate contentKey="CorpvineApp.accounts.modified">
                Modified
              </Translate>
            </span>
          </dt>
          <dd>
            {accountsEntity.modified ? (
              <TextFormat
                value={accountsEntity.modified}
                type="date"
                format={APP_DATE_FORMAT}
              />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="CorpvineApp.accounts.created_by">
              Created By
            </Translate>
          </dt>
          <dd>
            {accountsEntity.created_by ? accountsEntity.created_by.login : ""}
          </dd>
          <dt>
            <Translate contentKey="CorpvineApp.accounts.modified_by">
              Modified By
            </Translate>
          </dt>
          <dd>
            {accountsEntity.modified_by ? accountsEntity.modified_by.login : ""}
          </dd>
          <dt>
            <Translate contentKey="CorpvineApp.accounts.country">
              Country Id
            </Translate>
          </dt>
          <dd>
            {accountsEntity.country ? accountsEntity.country.country_name  : ""}
          </dd>
          <dt>
            <Translate contentKey="CorpvineApp.accounts.state">
              State Id
            </Translate>
          </dt>
          <dd>{accountsEntity.state ? accountsEntity.state.stateName : ""}</dd>
        </dl>
        <Button
          tag={Link}
          to="/accounts"
          replace
          color="info"
          data-cy="entityDetailsBackButton"
        >
          <FontAwesomeIcon icon="arrow-left" />{" "}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button
          tag={Link}
          to={`/accounts/${accountsEntity.id}/edit`}
          replace
          color="primary"
        >
          <FontAwesomeIcon icon="pencil-alt" />{" "}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default AccountsDetail;
