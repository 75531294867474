import axios from "axios";
import { createAsyncThunk, isFulfilled, isPending } from "@reduxjs/toolkit";
import { cleanEntity } from "app/shared/util/entity-utils";
import {
  EntityState,
  IQueryParams,
  createEntitySlice,
  serializeAxiosError,
} from "app/shared/reducers/reducer.utils";
import { ISuUser, defaultValue } from "app/shared/model/su-user.model";

const initialState: EntityState<ISuUser> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = "api/su-users";

// Actions

export const getEntities = createAsyncThunk(
  "suUser/fetch_entity_list",
  async ({ page, size, sort }: IQueryParams) => {
    const requestUrl = `${apiUrl}?${sort ? `page=${page}&size=${size}&sort=${sort}&` : ""}cacheBuster=${new Date().getTime()}`;
    return axios.get<ISuUser[]>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const getAllEntitiesList = createAsyncThunk(
  "suUser/fetch_entity_list",
  async ({ page, size, sort }: IQueryParams) => {
    const requestUrl = `${apiUrl}/all?${sort ? `page=${page}&size=${size}&sort=${sort}&` : ""}cacheBuster=${new Date().getTime()}`;
    return axios.get<ISuUser[]>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const getEntity = createAsyncThunk(
  "suUser/fetch_entity",
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<ISuUser>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const createEntity = createAsyncThunk(
  "suUser/create_entity",
  async (entity: ISuUser, thunkAPI) => {
    const result = await axios.post<ISuUser>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getAllEntitiesList({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const updateEntity = createAsyncThunk(
  "suUser/update_entity",
  async (entity: ISuUser, thunkAPI) => {
    const result = await axios.put<ISuUser>(
      `${apiUrl}/${entity.id}`,
      cleanEntity(entity),
    );
    thunkAPI.dispatch(getAllEntitiesList({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const partialUpdateEntity = createAsyncThunk(
  "suUser/partial_update_entity",
  async (entity: ISuUser, thunkAPI) => {
    const result = await axios.patch<ISuUser>(
      `${apiUrl}/${entity.id}`,
      cleanEntity(entity),
    );
    thunkAPI.dispatch(getAllEntitiesList({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const deleteEntity = createAsyncThunk(
  "suUser/delete_entity",
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<ISuUser>(requestUrl);
    thunkAPI.dispatch(getAllEntitiesList({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

// slice

export const SuUserSlice = createEntitySlice({
  name: "suUser",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, (state) => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getAllEntitiesList), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers["x-total-count"], 10),
        };
      })
      .addMatcher(
        isFulfilled(createEntity, updateEntity, partialUpdateEntity),
        (state, action) => {
          state.updating = false;
          state.loading = false;
          state.updateSuccess = true;
          state.entity = action.payload.data;
        },
      )
      .addMatcher(isPending(getAllEntitiesList, getEntity), (state) => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(
        isPending(
          createEntity,
          updateEntity,
          partialUpdateEntity,
          deleteEntity,
        ),
        (state) => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.updating = true;
        },
      );
  },
});

export const { reset } = SuUserSlice.actions;

// Reducer
export default SuUserSlice.reducer;
