import dayjs from "dayjs";

export interface ISuUser {
  id?: number;
  login?: string;
  password_hash?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  email?: string | null;
  image_url?: string | null;
  activated?: number | null;
  lang_key?: string | null;
  activation_key?: string | null;
  reset_key?: string | null;
  created_by?: string | null;
  created_date?: dayjs.Dayjs | null;
  reset_date?: dayjs.Dayjs | null;
  last_modified_by?: string | null;
  last_modified_date?: dayjs.Dayjs | null;
}

export const defaultValue: Readonly<ISuUser> = {};
