import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { TextFormat, Translate } from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { APP_DATE_FORMAT } from "app/config/constants";
import { useAppDispatch, useAppSelector } from "app/config/store";

import { getEntity } from "./title-position-link.reducer";

export const TitlePositionLinkDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<"id">();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const titlePositionLinkEntity = useAppSelector(
    (state) => state.titlePositionLink.entity,
  );
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="titlePositionLinkDetailsHeading">
          <Translate contentKey="CorpvineApp.titlePositionLink.detail.title">
            TitlePositionLink
          </Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{titlePositionLinkEntity.id}</dd>
          <dt>
            <span id="isactive">
              <Translate contentKey="CorpvineApp.titlePositionLink.isactive">
                Isactive
              </Translate>
            </span>
          </dt>
          <dd>{titlePositionLinkEntity.isactive}</dd>
          <dt>
            <span id="createddate">
              <Translate contentKey="CorpvineApp.titlePositionLink.createddate">
                Createddate
              </Translate>
            </span>
          </dt>
          <dd>
            {titlePositionLinkEntity.createddate ? (
              <TextFormat
                value={titlePositionLinkEntity.createddate}
                type="date"
                format={APP_DATE_FORMAT}
              />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="CorpvineApp.titlePositionLink.title">
              Title
            </Translate>
          </dt>
          <dd>
            {titlePositionLinkEntity.title
              ? titlePositionLinkEntity.title.jobtitlename
              : ""}
          </dd>
          <dt>
            <Translate contentKey="CorpvineApp.titlePositionLink.position">
              Position
            </Translate>
          </dt>
          <dd>
            {titlePositionLinkEntity.position
              ? titlePositionLinkEntity.position.positionname
              : ""}
          </dd>
        </dl>
        <Button
          tag={Link}
          to="/title-position-link"
          replace
          color="info"
          data-cy="entityDetailsBackButton"
        >
          <FontAwesomeIcon icon="arrow-left" />{" "}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button
          tag={Link}
          to={`/title-position-link/${titlePositionLinkEntity.id}/edit`}
          replace
          color="primary"
        >
          <FontAwesomeIcon icon="pencil-alt" />{" "}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default TitlePositionLinkDetail;
