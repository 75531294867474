import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import {
  isNumber,
  Translate,
  ValidatedField,
  ValidatedForm,
  translate,
} from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  convertDateTimeFromServer,
  convertDateTimeToServer,
  displayDefaultDateTime,
} from "app/shared/util/date-utils";
import { useAppDispatch, useAppSelector } from "app/config/store";

import { getEntities as getSuUsers } from "app/entities/su-user/su-user.reducer";
import { getEntities as getCountries } from "app/entities/country/country.reducer";
import { getEntities as getStates } from "app/entities/state/state.reducer";
import {
  createEntity,
  getEntity,
  reset,
  updateEntity,
} from "./accounts.reducer";

export const AccountsUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<"id">();
  const isNew = id === undefined;

  const suUsers = useAppSelector((state) => state.suUser.entities);
  const countries = useAppSelector((state) => state.country.entities);
  const states = useAppSelector((state) => state.state.entities);
  const accountsEntity = useAppSelector((state) => state.accounts.entity);
  const loading = useAppSelector((state) => state.accounts.loading);
  const updating = useAppSelector((state) => state.accounts.updating);
  const updateSuccess = useAppSelector((state) => state.accounts.updateSuccess);

  const handleClose = () => {
    navigate("/accounts");
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getSuUsers({}));
    dispatch(getCountries({}));
    dispatch(getStates({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = (values) => {
    if (values.id !== undefined && typeof values.id !== "number") {
      values.id = Number(values.id);
    }
    if (values.isActive !== undefined && typeof values.isActive !== "number") {
      values.isActive = Number(values.isActive);
    }
    values.created = convertDateTimeToServer(values.created);
    values.modified = convertDateTimeToServer(values.modified);

    const entity = {
      ...accountsEntity,
      ...values,
      created_by: suUsers.find(
        (it) => it.id.toString() === values.created_by?.toString(),
      ),
     modified_by: isNew?(suUsers.find(
             (it) => it.id.toString() === values.created_by.toString()
     ))
     :(suUsers.find(
       (it) => it.id.toString() === values.modified_by.toString()
     )),
      country: countries.find(
        (it) => it.id.toString() === values.country?.toString(),
      ),
      state: states.find(
        (it) => it.id.toString() === values.state?.toString(),
      ),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          created: displayDefaultDateTime(),
          modified: displayDefaultDateTime(),
          modified_by: accountsEntity?.created_by?.id,
        }
      : {
          ...accountsEntity,
          created: convertDateTimeFromServer(accountsEntity.created),
          modified: displayDefaultDateTime(),
          created_by: accountsEntity?.created_by?.id,

          country: accountsEntity?.country?.id,
          state: accountsEntity?.state?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2
            id="CorpvineApp.accounts.home.createOrEditLabel"
            data-cy="AccountsCreateUpdateHeading"
          >
            <Translate contentKey="CorpvineApp.accounts.home.createOrEditLabel">
              Create or edit a Accounts
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm
              defaultValues={defaultValues()}
              onSubmit={saveEntity}
            >
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="accounts-id"
                  label={translate("global.field.id")}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate("CorpvineApp.accounts.clientName")}
                id="accounts-clientName"
                name="clientName"
                data-cy="clientName"
                type="text"
                required
              />
              <ValidatedField
                label={translate("CorpvineApp.accounts.email")}
                id="accounts-email"
                name="email"
                data-cy="email"
                type="text"
                required
              />
              <ValidatedField
                label={translate("CorpvineApp.accounts.phoneNo")}
                id="accounts-phoneNo"
                name="phoneNo"
                data-cy="phoneNo"
                type="text"
              />
              <ValidatedField
                label={translate("CorpvineApp.accounts.poc")}
                id="accounts-poc"
                name="poc"
                data-cy="poc"
                type="text"
                required
              />
              <ValidatedField
                label={translate("CorpvineApp.accounts.address")}
                id="accounts-address"
                name="address"
                data-cy="address"
                type="text"
              />
              <ValidatedField
                label={translate("CorpvineApp.accounts.fax")}
                id="accounts-fax"
                name="fax"
                data-cy="fax"
                type="text"
              />
              <ValidatedField
                label={translate("CorpvineApp.accounts.isActive")}
                id="accounts-isActive"
                name="isActive"
                data-cy="isActive"
                placeholder="1-Active or 0-Inactive"
                type="text"
                validate={{
                  required: {
                    value: true,
                    message: translate("entity.validation.required"),
                  },
                  min: {
                    value: 0,
                    message: translate("entity.validation.min", { min: 0 }),
                  },
                  max: {
                    value: 1,
                    message: translate("entity.validation.max", { max: 1 }),
                  },
                  validate: (v) =>
                    isNumber(v) || translate("entity.validation.number"),
                }}
              />
              {/*
              <ValidatedField
                label={translate("CorpvineApp.accounts.created")}
                id="accounts-created"
                name="created"
                data-cy="created"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate("CorpvineApp.accounts.modified")}
                id="accounts-modified"
                name="modified"
                data-cy="modified"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              */}
              {isNew
                  ?(
              <ValidatedField
                id="accounts-created_by"
                name="created_by"
                data-cy="created_by"
                label={translate("CorpvineApp.accounts.created_by")}
                type="select"
                required
              >
                <option value="" key="0" />
                {suUsers
                  ? suUsers.map((otherEntity) => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.login}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              ):null
            }
                {!isNew
                ?(
              <ValidatedField
                id="accounts-modified_by"
                name="modified_by"
                data-cy="modified_by"
                label={translate("CorpvineApp.accounts.modified_by")}
                type="select"
                required
              >
                <option value="" key="0" />
                {suUsers
                  ? suUsers.map((otherEntity) => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.login}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              ):null
                }
              <ValidatedField
                id="accounts-country"
                name="country"
                data-cy="country"
                label={translate("CorpvineApp.accounts.country")}
                type="select"
              >
                <option value="" key="0" />
                {countries
                  ? countries.map((otherEntity) => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.country_name}
                      </option>
                    ))
                  : null}
              </ValidatedField>


              <ValidatedField
                id="accounts-state"
                name="state"
                data-cy="state"
                label={translate("CorpvineApp.accounts.state")}
                type="select"
              >
                <option value="" key="0" />
                {states
                  ? states.map((otherEntity) => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.stateName}
                      </option>
                    ))
                  : null}
              </ValidatedField>


              <Button
                tag={Link}
                id="cancel-save"
                data-cy="entityCreateCancelButton"
                to="/accounts"
                replace
                color="info"
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button
                color="primary"
                id="save-entity"
                data-cy="entityCreateSaveButton"
                type="submit"
                disabled={updating}
              >
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AccountsUpdate;
