import dayjs from "dayjs";
import { IOrganisation } from "app/shared/model/organisation.model";
import { ISuUser } from "app/shared/model/su-user.model";

export interface IDivision {
  id?: number;
  division_code?: string | null;
  division_name?: string | null;
  division_description?: string | null;
  is_active?: number | null;
  created_date?: dayjs.Dayjs | null;
  modified_date?: dayjs.Dayjs | null;
  organisation?: IOrganisation | null;
  modifiedby?: ISuUser | null;
  createdby?: ISuUser | null;
}

export const defaultValue: Readonly<IDivision> = {};
