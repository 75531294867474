import dayjs from "dayjs";
import { IMainJobTitles } from "app/shared/model/main-job-titles.model";
import { IMainPositions } from "app/shared/model/main-positions.model";

export interface ITitlePositionLink {
  id?: number;
  isactive?: number | null;
  createddate?: dayjs.Dayjs | null;
  title?: IMainJobTitles;
  position?: IMainPositions;
}

export const defaultValue: Readonly<ITitlePositionLink> = {};
