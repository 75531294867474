import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Table } from "reactstrap";
import { TextFormat, Translate, getSortState } from "react-jhipster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import { APP_DATE_FORMAT } from "app/config/constants";
import { ASC, DESC } from "app/shared/util/pagination.constants";
import { overridePaginationStateWithQueryParams } from "app/shared/util/entity-utils";
import { useAppDispatch, useAppSelector } from "app/config/store";

import { getEntities } from "./country.reducer";

export const Country = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [sortState, setSortState] = useState(
    overridePaginationStateWithQueryParams(
      getSortState(pageLocation, parseInt("id")),
      pageLocation.search,
    ),
  );

  const countryList = useAppSelector((state) => state.country.entities);
  const loading = useAppSelector((state) => state.country.loading);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        sort: `${sortState.sort},${sortState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?sort=${sortState.sort},${sortState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [sortState.order, sortState.sort]);

  const sort = (p) => () => {
    setSortState({
      ...sortState,
      order: sortState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = sortState.sort;
    const order = sortState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    }
    return order === ASC ? faSortUp : faSortDown;
  };

  return (
    <div>
      <h2 id="country-heading" data-cy="CountryHeading">
        <Translate contentKey="CorpvineApp.country.home.title">
          Countries
        </Translate>
        <div className="d-flex justify-content-end">
          <Button
            className="me-2"
            color="info"
            onClick={handleSyncList}
            disabled={loading}
          >
            <FontAwesomeIcon icon="sync" spin={loading} />{" "}
            <Translate contentKey="CorpvineApp.country.home.refreshListLabel">
              Refresh List
            </Translate>
          </Button>
          <Link
            to="/country/new"
            className="btn btn-primary jh-create-entity"
            id="jh-create-entity"
            data-cy="entityCreateButton"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="CorpvineApp.country.home.createLabel">
              Create new Country
            </Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {countryList && countryList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort("id")}>
                  <Translate contentKey="CorpvineApp.country.id">ID</Translate>{" "}
                  <FontAwesomeIcon icon={getSortIconByFieldName("id")} />
                </th>
                <th className="hand" onClick={sort("country_name")}>
                  <Translate contentKey="CorpvineApp.country.country_name">
                    Country Name
                  </Translate>{" "}
                  <FontAwesomeIcon
                    icon={getSortIconByFieldName("country_name")}
                  />
                </th>
                <th className="hand" onClick={sort("country_code")}>
                  <Translate contentKey="CorpvineApp.country.country_code">
                    Country Code
                  </Translate>{" "}
                  <FontAwesomeIcon
                    icon={getSortIconByFieldName("country_code")}
                  />
                </th>
                <th className="hand" onClick={sort("country_code2")}>
                  <Translate contentKey="CorpvineApp.country.country_code2">
                    Country Code 2
                  </Translate>{" "}
                  <FontAwesomeIcon
                    icon={getSortIconByFieldName("country_code2")}
                  />
                </th>
                <th className="hand" onClick={sort("is_active")}>
                  <Translate contentKey="CorpvineApp.country.is_active">
                    Is Active
                  </Translate>{" "}
                  <FontAwesomeIcon icon={getSortIconByFieldName("is_active")} />
                </th>
                <th className="hand" onClick={sort("created")}>
                  <Translate contentKey="CorpvineApp.country.created">
                    Created
                  </Translate>{" "}
                  <FontAwesomeIcon icon={getSortIconByFieldName("created")} />
                </th>
                <th className="hand" onClick={sort("modified")}>
                  <Translate contentKey="CorpvineApp.country.modified">
                    Modified
                  </Translate>{" "}
                  <FontAwesomeIcon icon={getSortIconByFieldName("modified")} />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {countryList.map((country, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button
                      tag={Link}
                      to={`/country/${country.id}`}
                      color="link"
                      size="sm"
                    >
                      {country.id}
                    </Button>
                  </td>
                  <td>{country.country_name}</td>
                  <td>{country.country_code}</td>
                  <td>{country.country_code2}</td>
                  <td>{country.is_active ? "true" : "false"}</td>
                  <td>
                    {country.created ? (
                      <TextFormat
                        type="date"
                        value={country.created}
                        format={APP_DATE_FORMAT}
                      />
                    ) : null}
                  </td>
                  <td>
                    {country.modified ? (
                      <TextFormat
                        type="date"
                        value={country.modified}
                        format={APP_DATE_FORMAT}
                      />
                    ) : null}
                  </td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        tag={Link}
                        to={`/country/${country.id}`}
                        color="info"
                        size="sm"
                        data-cy="entityDetailsButton"
                      >
                        <FontAwesomeIcon icon="eye" />{" "}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">
                            View
                          </Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/country/${country.id}/edit`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{" "}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">
                            Edit
                          </Translate>
                        </span>
                      </Button>
                      <Button
                        onClick={() =>
                          (window.location.href = `/country/${country.id}/delete`)
                        }
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{" "}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">
                            Delete
                          </Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="CorpvineApp.country.home.notFound">
                No Countries found
              </Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Country;
