import React from "react";
import { Route } from "react-router-dom";

import ErrorBoundaryRoutes from "app/shared/error/error-boundary-routes";

import SuUser from "./su-user";
import SuUserDetail from "./su-user-detail";
import SuUserUpdate from "./su-user-update";
import SuUserDeleteDialog from "./su-user-delete-dialog";

const SuUserRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<SuUser />} />
    <Route path="new" element={<SuUserUpdate />} />
    <Route path=":id">
      <Route index element={<SuUserDetail />} />
      <Route path="edit" element={<SuUserUpdate />} />
      <Route path="delete" element={<SuUserDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default SuUserRoutes;
